.Nav {
  display: flex;
  align-items: center;

  @media (max-width: 374px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}
.Nav__item {
  padding: 0.4em;

  font-size: 1.1rem;
  text-decoration: none;
  color: #444;

  transition: 500ms;

  &:hover {
    color: #333;
  }

  &:first-child {
    display: none;
  }

  @media (min-width: 520px) {
    font-size: 1.2rem;

    &:first-child {
      display: initial;
    }
  }
  @media (min-width: 620px) {
    padding: 0.8em 1em;
  }
  @media (min-width: 720px) {
    margin-left: 1em;
    padding: 0.8em 1.5em;
  }
  @media (min-width: 920px) {
    font-size: 1.4rem;
  }
}
