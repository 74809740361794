.Services__container {
  @extend .Container;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.Services__title {
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
}
.Services__sub-title {
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
}
.Services__list {
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
  list-style: none;
  margin: 0 auto;
  padding: 0;
  max-width: 960px;
  font-size: 1.3rem;

  &--packages {
    margin-top: 0.5rem;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
}
.Services__list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  text-align: center;
  background-color: rgba(white, 0.5);
  transition-duration: 600ms;

  &:hover {
    background-color: white;
    transition-duration: 300ms;
  }

  h3 {
    margin: 0;

    &::after {
      content: ' ';
      display: block;
      margin: 1.5rem auto;
      width: 33%;
      height: 1px;
      background-color: black;
    }
  }

  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    cursor: default;
    text-align: initial;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0.5rem;
    transition-duration: 500ms;
    transition-delay: 300ms;

    &:hover {
      transition-delay: 0s;
      transition-duration: 300ms;
      background-color: rgba(#c2afa8, 0.1);
    }

    > :last-child {
      margin-left: 1rem;
      text-align: right;
    }
  }

  small {
    display: block;
    margin-top: 0.75rem;
  }
}
.Services__list-item--form {
  &,
  &:hover {
    background-color: transparent;
  }

  @media (min-width: 615px) and (max-width: 911px) {
    grid-column-end: span 2;
  }
}
.Services__list-item--tall {
  grid-row-end: span 2;
}
.Services__list-item--wide {
  @media (min-width: 615px) {
    grid-column-end: span 2;
  }
}
.Services__list-item--last {
  @media (min-width: 615px) and (max-width: 911px) {
    grid-column-end: span 2;
  }
}
