.Contact {
  @extend .Container;
  padding-top: 3.5rem;
  padding-bottom: 6rem;
}
.Contact__title {
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
}
.Contact__container {
  @media (min-width: 520px) {
    padding: 0 2rem;
  }
  @media (max-width: 920px) {
    margin: 0 auto;
    max-width: 720px;
  }
  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 3rem;
  }
}
.Contact__header {
  grid-row-start: 1;
  grid-column-start: 2;
  text-align: center;
  font-size: 1.2rem;

  @media (max-width: 920px) {
    margin-bottom: 2rem;
  }
  @media (min-width: 920px) {
    justify-self: flex-end;
    text-align: right;
  }
}
.Contact__body {
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-row-start: 2;
  grid-column-start: 2;
  padding: 2rem;
  background-color: white;

  @media (min-width: 920px) {
    padding: 2rem 3rem;
  }
}
.Contact__list {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;

  li {
    display: flex;
    justify-content: space-between;

    & + li {
      margin-top: 1rem;
    }

    > :last-child {
      margin-left: 1rem;
      text-align: right;
    }
  }

  abbr {
    text-decoration: none;

    @media (min-width: 520px) {
      font-size: 0;
      &:after {
        content: attr(title);
        font-size: 1.3rem;
      }
    }
  }

  a {
    font-style: italic;
    text-decoration: none;
    color: #284177;
  }
}
.Contact__map {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: span 2;

  border: none;
  width: 100%;
  height: 100%;
  min-height: 25rem;
  max-height: 30rem;
  vertical-align: middle;

  background-color: #284177;
}
