@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Hero {
  position: relative;
}
.Hero__picture {
  display: block;
}
.Hero__image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: left top;
  vertical-align: middle;

  animation: fadein 3000ms ease-in-out;

  @media (max-width: 920px) {
    object-position: -160px top;
  }
}
.Hero__positioner {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Hero__container {
  @extend .Container;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 3rem;

  @media (min-width: $container-max-width) {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
.Hero__content {
  border-radius: 1rem;
  padding: 2.5rem;
  width: 35rem;
  max-width: 90vw;
  color: darken(gray, 30);

  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
  > :nth-child(1),
  > :nth-child(2) {
    display: none;
  }

  @media (min-width: 35rem) {
    background-color: rgba(#ede8e4, 0.8);

    > :nth-child(1),
    > :nth-child(2) {
      display: block;
    }
  }
}
