.About {
  @extend .Container;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.About__title {
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
}
.About__container {
  display: grid;

  @media (min-width: 520px) {
    padding: 0 2rem;
  }
  @media (max-width: 920px) {
    margin: 0 auto;
    max-width: 720px;
  }
  @media (min-width: 920px) {
    gap: 3rem;
  }
}
.About__header {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: span 2;
  font-size: 1.2rem;

  @media (min-width: 520px) {
    grid-row-start: 1;
  }
  @media (max-width: 920px) {
    position: relative;
    z-index: 1;
    align-self: flex-end;
    padding: 2rem;
    width: 100%;
    background-color: rgba(white, 0.8);
  }
  @media (min-width: 1020px) {
    grid-column-end: 1;
  }
}
.About__body {
  grid-row-start: 3;
  grid-column-start: 1;
  align-self: flex-end;

  padding: 2rem 3rem;
  background-color: white;

  @media (min-width: 520px) {
    grid-row-start: 2;
  }
}
.About__picture {
  grid-row-start: 1;
  grid-column-start: 1;
  background-color: #284177;

  @media (min-width: 920px) {
    grid-row-start: 2;
    grid-column-start: 2;
  }
  @media (min-width: 1020px) {
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}
.About__image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}
