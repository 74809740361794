$blue: #284177;
$light-blue: lighten($blue, 50);
$lighter-blue: lighten($blue, 60);

.Footer {
  margin-top: 5rem;
  color: $light-blue;
  background-color: $blue;

  a {
    color: $light-blue;
    text-decoration: none;
    transition: 300ms;

    &:hover {
      color: $lighter-blue;
    }
  }

  .Logo {
    max-width: 10rem;
  }
  .Logo__image {
    display: none;
  }
}
.Footer__content {
  @extend .Container;
  display: flex;
  padding: 5rem 2rem;
  @media (max-width: 920px) {
    display: flex;
    justify-content: center;
  }
}
.Footer__section {
  padding: 0 1rem;
  text-align: right;
  font-size: 1.3rem;

  &:first-child {
    margin-right: auto;
    align-self: center;
    @media (max-width: 920px) {
      display: none;
    }
  }
  &:last-child {
    @media (max-width: 920px) {
      text-align: left;
      li {
        justify-content: flex-start;
      }
    }
  }

  @media (min-width: 720px) {
    padding: 0 3rem;
  }
  @media (min-width: 920px) {
    max-width: 33%;
  }
}
.Footer__section-title {
  color: $lighter-blue;
}
.Footer__nav-item {
  display: block;
  margin-bottom: 0.75rem;
}
.Footer__list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    > :last-child {
      margin-left: 1rem;
      font-style: italic;
    }
  }
}

.Footer__copyright {
  margin: 0 auto;
  border-top: 1px solid lighten($blue, 10);
  padding: 1rem 5em;
  max-width: 30rem;
  width: 100%;
  text-align: center;
}
.Footer__fancy {
  font-family: 'Tangerine', cursive;
  font-size: 1.4rem;
}
