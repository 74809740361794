.Logo {
  display: flex;
  align-items: center;
  margin: 0;

  color: inherit;
  font-size: #{0.85 * 1.5rem};
  text-align: left;

  transition: 500ms;
  will-change: font-size;

  @media (min-width: 920px) {
    font-size: #{1.4 * 1.5rem};

    .Header--scrolled & {
      font-size: #{1 * 1.5rem};
    }
  }
}
.Logo__image {
  vertical-align: middle;
  margin-right: #{0.85 * 0.35rem};
  height: #{0.85 * 4.4rem};
  min-height: 100%;
  width: auto;

  transition: 500ms;

  @media (min-width: 920px) {
    margin-right: #{1.4 * 0.35rem};
    height: #{1.4 * 4.4rem};

    .Header--scrolled & {
      margin-right: #{1 * 0.35rem};
      height: #{1 * 4.4rem};
    }
  }
}
.Logo__body {
  display: flex;
  flex-direction: column;
}
.Logo__secondary {
  font-family: 'Tangerine', cursive;
  font-size: 0.9em;
}
