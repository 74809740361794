$container-max-width: 1366px;

@mixin visually-hidden {
  overflow: hidden;
  position: absolute;
  margin: -1px;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

@mixin container-padding {
  padding: 0.5rem 1rem;

  @media (min-width: 945px) {
    padding: 0.5rem 2rem;
  }
}

* {
  box-sizing: border-box;
}

@import './components/container.scss';
@import './components/site.scss';
@import './components/common.scss';
@import './components/header.scss';
@import './components/logo.scss';
@import './components/nav.scss';
@import './components/hero.scss';
@import './components/form.scss';
@import './components/certifications.scss';
@import './components/services.scss';
@import './components/about.scss';
@import './components//contact.scss';
@import './components/footer.scss';
