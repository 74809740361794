.Site {
  color: #444;
  font-family: 'Cormorant Garamond', serif;
  background-color: #ede8e4;

  @media (max-width: 400px) {
    font-size: 0.9em;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.Site__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
