.Header {
  $header-background: rgba(white, 0.96);
  z-index: 10;
  position: fixed;
  width: 100%;
  transition: 300ms;

  &--scrolled {
    background-color: $header-background;
  }

  @media (max-width: 1620px) and (min-aspect-ratio: 4/3) {
    background-color: $header-background;
  }
  @media (max-width: 1420px) {
    background-color: $header-background;
  }
}
.Header__content {
  @include container-padding;
  display: flex;
  align-content: center;
  justify-content: space-between;
}
.Header__logo {
  display: flex;
  align-items: center;
  color: darken(gray, 30);
  text-decoration: none;
}
