@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.Form {
  position: relative;
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: 33% 15% 15% 33%;
  gap: 0.5rem;
  text-align: left;
  transition: 400ms;

  &--small {
    grid-template-columns: 45% 5% 5% 45%;
  }

  &--contact {
    margin-top: 2rem;
    width: 100%;
  }

  &--submitting {
    opacity: 0.3;
  }
}
.Form__input {
  grid-column-end: span 2;
  &--wide {
    grid-column-end: span 3;
  }
  &--full-width {
    grid-column-end: span 4;
  }
}
.Form__input-error {
  display: none;

  .Form__input--error & {
    @extend %Form__error;
  }
}
.Form__label {
  @include visually-hidden;
}
.Form__field {
  display: block;
  border: 1px solid #c2afa8;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  width: 100%;
  background-color: lighten(#ede8e4, 5);

  &:hover,
  &:active {
    border-color: darken(#c2afa8, 30);
  }

  .Form__input--error & {
    border-color: lightcoral;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (min-width: 920px) {
    font-size: 1.2rem;
  }
}
.Form__button {
  border: none;
  border-radius: 0.25rem;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1;
  color: lighten(#ede8e4, 5);
  background-color: #284177;

  &:hover {
    background-color: darken(#284177, 10);
  }
  &:active {
    background-color: lighten(#284177, 10);
  }

  &--wide {
    grid-column-end: span 2;
  }
  &--right {
    grid-column-start: 4;
  }

  @media (min-width: 920px) {
    font-size: 1.2rem;
  }

  .Form--submitting & {
    font-size: 0;

    &::before,
    &::after {
      --gap: 60deg;
      --start: calc(var(--gap) / 2);
      --end: calc(360deg - var(--start));
      content: '';
      display: inline-block;
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-radius: 50%;
      width: 1rem;
      height: 1rem;
      animation: spin 0.65s infinite linear;
    }
    &::after {
      animation-direction: reverse;
    }
  }
}
.Form__footer {
  display: none;
  grid-column-end: span 4;

  .Form--success & {
    @extend %Form__success;
  }
  .Form--error & {
    @extend %Form__error;
  }
}
%Form__notice {
  display: inline-block;
  padding: 0 1rem;
  width: 100%;
  color: white;
  font-size: 0.8rem;
  line-height: 1.5;

  a {
    font-style: italic;
    color: white;
  }
}
%Form__success {
  @extend %Form__notice;
  background-color: lightseagreen;
}
%Form__error {
  @extend %Form__notice;
  background-color: lightcoral;
}
