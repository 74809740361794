.Certifications {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: white;
}
.Certifications__title {
  text-align: center;
  font-size: 4rem;
  font-weight: 400;
  @include visually-hidden;
}
.Certifications__list {
  @extend .Container;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.Certifications__list-item {
  margin: 0 0.5rem;
}
.Certifications__image {
  width: 100%;
  height: 100%;
  max-width: 300px;
  max-height: 100px;
  vertical-align: middle;
  object-fit: contain;
}
